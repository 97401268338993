import {
  Link as GLink,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { Box, Flex, Link } from 'juhuui';
import React from 'react';

import Address from '../components/address';
import { Button } from '../components/button';
import Hero from '../components/hero';
import SEO from '../components/seo';
import Subheader from '../components/subheader';
import {
  ContainerOuter,
  grid6,
  grid12,
  GridHalfFull,
  MainContent,
  TextLg,
  TextMd,
} from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';

interface KontaktProps {
  data: GraphQLData;
}

export const KontaktTemplate = ({ data, subheaderIntro }: KontaktProps) => {
  const { t } = useTranslation();

  const { subheader, meta, hero } =
    getLocalizationObj(data.strapi?.contact) || {};
  const { title = 'Kontakt', description } = meta || {};
  const { openingHours, phone, email, googleMapsLink } = getLocalizationObj(
    data.strapi?.fact
  );

  return (
    <>
      <SEO description={description} title={title} />
      <Subheader intro={subheaderIntro || subheader} />
      <MainContent>
        <ContainerOuter
          className={grid12}
          css={{
            pb: [16, undefined, 0],
            margin: 'auto',
            maxW: 'var(--max-width)',
            px: 'var(--px)',
          }}
        >
          <GridHalfFull fw={{ first: true }}>
            <TextLg as="div" css={{ m: '0', p: '0' }}>
              <p>{t('book an appointment')}</p>
              <Link
                css={{
                  display: 'block',
                }}
                href={`tel:+49${phone}`}
              >
                {t('at')} {phone}
              </Link>
              <Link
                css={{
                  display: 'block',
                }}
                href={`mailto: ${email}`}
              >
                {email}
              </Link>
            </TextLg>
          </GridHalfFull>
          <GridHalfFull>
            <Box className={grid6} css={{ py: ['8', '0'] }}>
              <TextMd
                as="div"
                css={{
                  column: [
                    '1/span 6',
                    undefined,
                    openingHours ? '1/span 3' : '1/span 6',
                  ],
                }}
              >
                <Address />
                <Flex>
                  <Button
                    css={{
                      c: 'tom.textHighlight',
                      bg: 'inherit',
                      border: '1px solid tom.line',
                      mt: [6],
                      '&:hover': {
                        bg: 'tom.bg',
                      },
                    }}
                    href={googleMapsLink}
                    rel="noopener"
                    target="_blank"
                  >
                    {t('plan your journey')}
                  </Button>
                </Flex>
              </TextMd>
              {openingHours && (
                <TextMd
                  as="div"
                  css={{
                    column: ['1/span 6', undefined, '4/span 3'],
                    mt: ['6', undefined, '0px'],
                  }}
                >
                  <div>{t('consultation hour')}</div>
                  <div>{openingHours}</div>
                </TextMd>
              )}
            </Box>
          </GridHalfFull>
        </ContainerOuter>
        <Hero alt={hero?.alternativeText} src={hero?.file} />
      </MainContent>
      {/* <KontaktTemplate data={data} /> */}
    </>
  );
};
