import { Box, Flex } from 'juhuui';
import React, { useEffect, useState } from 'react';

import Image from './image';

function Hero({ src, alt }) {
  return (
    <Flex
      css={{
        direction: 'column',
        margin: 'auto',
        overflow: 'hidden',
        pos: 'relative',
        w: 'full',
        zIndex: 3,
        bg: 'inherit',
      }}
      // maxW="calc(var(--max-width) - 3%)"
    >
      <Image
        alt={alt}
        h={['50vh', undefined, 'initial']}
        image={src}
        loading="eager"
      />
    </Flex>
  );
}

export default Hero;
